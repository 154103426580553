exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-imprint-jsx": () => import("./../../../src/pages/imprint.jsx" /* webpackChunkName: "component---src-pages-imprint-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-links-jsx": () => import("./../../../src/pages/links.jsx" /* webpackChunkName: "component---src-pages-links-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-talks-jsx": () => import("./../../../src/pages/talks.jsx" /* webpackChunkName: "component---src-pages-talks-jsx" */),
  "component---src-templates-blog-page-template-jsx": () => import("./../../../src/templates/blog-page-template.jsx" /* webpackChunkName: "component---src-templates-blog-page-template-jsx" */),
  "component---src-templates-blog-tag-page-template-jsx": () => import("./../../../src/templates/blog-tag-page-template.jsx" /* webpackChunkName: "component---src-templates-blog-tag-page-template-jsx" */),
  "component---src-templates-blog-year-page-template-jsx": () => import("./../../../src/templates/blog-year-page-template.jsx" /* webpackChunkName: "component---src-templates-blog-year-page-template-jsx" */)
}

